/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

import heroBg from './assets/contour-pattern.svg';

export default {
    heroContainerCustom: {
        position: `relative`,
        pt: [5, 6],
        '::before, ::after': {
            position: `absolute`,
            content: `" "`,
            width: [`full`, `90%`],
            height: `80%`,
            top: [`-10%`, 0],
            right: `50%`,
            transform: `translate(50%, 0)`,
            zIndex: -1,
        },
        '::before': {
            borderRadius: `xl`,
            background: t => `linear-gradient(
           180deg,
           ${t.colors.alphaLighter} 0%,
           ${t.colors.alphaLight} 100%
         )`,
        },
        '::after': {
            background: `url(${heroBg}) no-repeat right top`,
            backgroundSize: `50%`,
            opacity: 0.1,
        },
    },
    heroContainer: {
        '::before': {
            content: `" "`,
            size: `full`,
            position: `absolute`,
            top: `-10%`,
            left: 0,
            zIndex: -1,
            background: t =>
                `url(${heroBg}) no-repeat center 0, linear-gradient(
          180deg,
          ${t.colors.alphaDark} 0%,
          ${t.colors.alpha} 100%
        )`,
            backgroundSize: `100%`,
        },
    },
    strategiesContainer: {
        position: `relative`,
        '::before': {
            content: `" "`,
            width: `full`,
            height: `150%`,
            position: `absolute`,
            top: 0,
            left: 0,
            zIndex: -1,
            background: t =>
                `url(${heroBg}) no-repeat center 0, linear-gradient(
          180deg,
          ${t.colors.alphaLighter} 0%,
          ${t.colors.background} 100%
        )`,
            backgroundSize: `100%`,
        },
    },
    whyChooseUsContainer: {
        bg: `#2d3537`,
        borderRadius: `xl`,
        py: 5,
        px: [4, 0],
    },
};
